/* our custom styles */

// DELETE ME:
.custom-theme-marker {
    display: none;
    position: absolute;
    top: 300px;
    left: 30%;
    margin: auto 0;
    transform: rotate(-20deg);
    background: #00ECFF;
    color: #fff;
    padding: 1em;
    z-index: 100;
}
.section-front-page .custom-theme-marker{
    display: block;
}


/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

/* VO Theme */

@media (min-width: 1200px) {
    .container {width: 1000px;}
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #4d4d4d;
    background-color: #fafafa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: justify;
    line-height: 1.8em;
}

a {
    color: #282c2d;
    text-decoration: none;
    background: transparent;
}

h1.documentFirstHeading {
    padding-bottom: 9px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid rgb(204, 204, 204);
    font-size: 26px;
}

div#parent-fieldname-text > h3 {
    /* border-bottom: 1px solid rgb(204, 204, 204); */
    text-decoration: underline #cccccc;
    margin-bottom: 0.5em;
    }
    
div#parent-fieldname-text > h4 {
/*    border-bottom: 1px solid rgb(204, 204, 204); */
    text-decoration: underline #cccccc;
    margin: 20px 0 0.5em 0;
}

h5 {
    padding: 20px 0 5px;
    text-decoration: underline #cccccc;
}
div.documentDescription.description {
    font-size: 12px;
    color: rgb(105, 105, 105);
    margin-bottom: 10px;
    font-style: italic;
}

.image-left.speaker {
    width: 100px;
}

p.speaker {
    margin: 30px 0 0;
}

.invisible-grid {
    width: 100%;
    margin-bottom: 20px;
    border: 0;
    background: transparent;
}

table > tbody > tr > td, table > tfoot > tr > td {
   border: 0px solid rgba(241, 238, 238, 0.952);
    font-size: 12px;
    text-align: center;
    width: 200px;
}

table {
    width: 100%;
    max-width: 100%;
    border: 0px solid #fafafa;
    background-color: #fafafa;
}

table.committee {
    width: 540px;
}

table.committee2015 {
    width: 85%;
}

table.scommittee {
    width: 85%;
}

table.sponsors {
    border: 0px solid rgb(250, 250, 250);
    font-size: 12px;
    text-align: center;
    background: transparent;
    width: 150px;
    margin-left: 25px;    
}

table.sponsors td {
    padding-top: 10px;
}

table.invisible-grid.programme td {
    text-align: left;
}

td.time {
    text-align: left;
    width: 100px;
}

td.keynote {
    text-align: left;
    width: 20%;
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #7099bf;
}

.portlet {
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    text-align: center;
}

.portlet .portletHeader {
    background: #f2f1f1;
    font-size: 13px;
    padding: 5px 5px;
    color: #696969;
}

.portlet .portletContent {
    font-size: 10px;
}

div.portletStaticText.portlet-static-registration {
    text-align: center;
}

aside#portal-column-two {
    width: 200px;
}

fieldset {
  padding: 10px 10px 10px;
  min-width: max-content;
  border: 1px solid #c0c0c0;
}

legend {
  display: block;
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #4d4d4d;
  border: 0;
  border-bottom: 0;
  font-weight: 300;
}

.responsive-img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

.responsive-iframe {
    display: block;
    max-width: 100%;
    width: 560px;
    height: 314px;
  }

  img {
    max-width: 100%;
  }

  h5 {
      padding: 20px 0 30px;
  }

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

aside#portal-footer-signature.portlet.portletClassic {
    display: none;
}

#portal-footer-wrapper p {
    border-bottom: none;
    padding-bottom: 0;
}

#portal-footer-wrapper {
    padding: 20px 15px;
    background-color: #2e3133;
    color: #cccccc;
    text-align: center;
    clear: both;
}

img.logo {
    width: 1070px;
    float: left;
}

/* Login and Searchbox configuration */

#portal-anontools li {
    margin: 5px 0 12px 12px;
}

#portal-header #portal-searchbox .searchSection {
    display: none;
}

#portal-header #portal-searchbox form > div > * {
    font-size: 14px;
    height: 25px;
}

.principal {
    background: #fafafa;
    background-size: 20px 20px;
    /* margin-top: -20px; */
    /* margin-bottom: 20px; */
    padding: 15px 0;
}

div#hero.principal {
    padding-top: 2px;
}

div.LSBox {
    margin-right: 5px;
    height: 30px;
}

div#portal-searchbox {
    float: right;
    margin-left: 0.5em;
    z-index: 2;
}
/*
div#portal-anontools {
    width: 100px;
    float: right;
}
*/
div.searchSection {
    display: none;
}

input.searchButton {
    display: none;
}

input[type="text"], input[type="password"], textarea, select {
    display: block;
    width: 100%;
    height: 25px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #696969;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/* Plone navbar */

nav.plone-navbar {
    background-color: #fafafa;
    position: relative;
    min-height: 50px;
    margin-bottom: 10px;
/*    border: 1px solid transparent; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
*/
}

.plone-navbar {
    box-shadow: none;
}

.plone-navbar-toggle {
    z-index: 1;
    position: relative;
    float: right;
    margin-top: 6px;
    margin-bottom: 3px;
    padding: 6px 10px;
    background-color: #6a7480;
    background-image: none;
    border: 1px solid white;
    border-radius: 4px;
}

.plone-nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    color: #000000;
    font-size: 17px;
}

nav.plone-navbar a:hover {
    background-color: #a2a8ab; 
}

nav.plone-navbar-toggle {
    float: right;
    width: 55px;
    margin-right: 20px;
    padding-top: 5px;
}

.plone-navbar-toggle:hover {
    background-color: #a5b5c1;
    border-color: #a5b5c1;
}

.plone-navbar-nav > .selected > a, .plone-navbar-nav > .selected > a:hover, .plone-navbar-nav > .selected > a:focus {
    color: white;
    background-color: #c5c6c7;
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #bdb8b8;
}

/* footer */

#portal-footer-wrapper a {
    color: #ffffff;
}

/* Hide glyphicons */

i.glyphicon.link-external, i.glyphicon.link-https {
    display: none;
}

/* hide breadcrumb */
.plone-breadcrumb {
    clear: left;
    background-color: #e6e4e4;
    margin-bottom: 20px;
    margin-top: -20px;
    display: none;
}